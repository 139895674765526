<template>
  <div class="nd-radio-group w-full scrollbar">
    <n-radio-group v-model:value="computedValue" class="w-full">
      <template v-for="item in props.options" :key="item.id">
        <div class="px-5 py-3 hover:bg-gray-20 transition-colors" :class="props.itemClass">
          <n-radio class="w-full" :value="item.id!" :label="item.name" @click="handleRadioClick(item.id!)" />
        </div>
      </template>
    </n-radio-group>
    <scroll-mask v-if="showMask" />
  </div>
</template>

<script setup lang="ts">
import { NRadioGroup, NRadio } from 'naive-ui'

const modelValue = defineModel<string | number | null>('modelValue')
const value = defineModel<string | number | null>('value')

const computedValue = computed({
  get() {
    return modelValue.value || value.value
  },
  set(newValue) {
    if (newValue === modelValue.value || newValue === value.value) newValue = undefined
    modelValue.value = newValue
    value.value = newValue
  },
})

const props = withDefaults(
  defineProps<{
    options: {
      id?: string | number | null
      name?: string
    }[]
    cancelable?: boolean
    itemClass?: string
    showMask?: boolean
  }>(),
  {
    options: () => [],
    cancelable: false,
    itemClass: '',
    showMask: false,
  }
)

const handleRadioClick = (val: string | number) => {
  if (props.cancelable && computedValue.value === val) computedValue.value = null
}
</script>

<style lang="scss" scoped>
.nd-radio-group {
}
</style>
